<template>
  <div class="box" style="z-index: 999999999">
    <el-dialog
      v-dialogdrag
      title="局部段落"
      :visible="rangeDialogVisible"
      :close-on-click-modal="false"
      width="40%"
      @close="$emit('update:range-dialog-visible', false)"
    >
      <span class="content">
        <span v-show="content.content" v-html="content.content"> </span>
        <!-- <br /><br />
        <span v-show="content.content" style="color: rgb(117, 153, 193)"
          >[序号]</span
        >
        <span v-show="content.content">
          {{
            `${content.contributor ? content.contributor + "." : ""}${
              content.title
            }[M].${content.placeOfPublication}.${
              content.publisher ? content.publisher + "." : ""
            }${content.dateOfPublication}:第${content.actualPageIndex}页;`
          }}
        </span> -->
      </span>
      <div class="dialog-footer" style="text-align: end">
        <el-button style="background: #9cc3ce; color: #fff" @click="doCopy"
          >一键复制</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  props: {
    "range-dialog-visible": {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: "",
    },
    info: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      bookInfo: "",
      tempMessage: "",
    };
  },
  created() {
    this.bookInfo = this.$store.state.bookInfo || {};
  },
  methods: {
    dataProcessing(val) {
      console.log(this.content);
      this.tempMessage = this.content.content;
      // "\n" +
      // `[序号]${this.bookInfo.contributor}${
      //   this.bookInfo.contributor ? "." : ""
      // }${this.bookInfo.title}${this.bookInfo.title ? "." : ""}${
      //   this.bookInfo.seriesArea
      // }[M].${this.bookInfo.placeOfPublication}${
      //   this.bookInfo.placeOfPublication ? "." : ""
      // }${this.bookInfo.publisher}${this.bookInfo.publisher ? "." : ""}${
      //   this.bookInfo.dateOfPublication
      // }${this.bookInfo.dateOfPublication ? ":" : ""}${
      //   this.content.documentTitle
      // }第${this.content.actualPageIndex}页`;
    },
    doCopy(val) {
      this.dataProcessing(val);
      this.$copyText(this.tempMessage).then(
        (res) => {
          console.log(res);
          this.$message.success("复制成功");
        },
        (e) => {
          this.$message.error("复制失败");
          console.log(e);
        }
      );
    },
  },
  watch: {
    info: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.bookInfo = newVal;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
  
  <style lang="scss" scoped>
::v-deep .el-dialog__wrapper {
  // eslint-disable-line
  .el-dialog {
    height: 78vh;
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
      height: 30px;
      width: 35px;
    }
    .el-dialog__header {
      background: #9cc3ce;
    }
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn .el-dialog__close:hover {
      color: gray;
    }
  }
}

.content {
  font-size: 20px;
  color: #000;
  font-family: 宋体;
  line-height: 30px;
  letter-spacing: 2px;
  display: inline-block;
  height: 58vh;
  overflow: auto;
}
</style>