import request from '@/utils/request.js'

export const getClassifyList = () => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/slzs-paper/paper-type-list',
    })
}
export const cataloguePager = params => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/slzs-paper/catalogue-paper-list-show/${params.id}`,
        params: {
            level: params.level
        }
    })
}

export const getPaperData = params => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/slzs-paper/periodical-by-title`,
        params
    })
}

export const getPaperYear = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/slzs-paper/catalogue-paper-list-year',
        params
    })
}

// 获取基础数据
export const getBaseData = id => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/slzs-paper/${id}/catalogue-by-id`,

    })
}
export const getTree = params => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/slzs-paper/catalogue-paper-list-by-paper-name`,
        params

    })
}
// 电子书本数
export const getEBook = params => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/slzs-paper/catalogue-paper-list-show/${params.id}`,
        params: {
            level: params.level
        }
    })
}

// 电子书段落
export const getEBookContent = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/slzs-paper/periodical-content',
        params
    })
}

// 搜索
export const handlerSearch = params => {
    return request({
        method: 'POST',
        url: '/knowledgeshow/slzs-paper/search-content',
        params
    })
}

// 获取电子书原图
export const getPic = params => {
    return request({
        method: 'POST',
        url: `/knowledgeshow/ebook/e-book-real-pic/${params.ebookId}`,
        params: {
            pageIndex: params.pageIndex
        }
    })
}

// 翻页
export const BeforeOrNext = data => {
    return request({
        method: 'POST',
        url: `/knowledgeshow/ebook/${data.id}/befor-or-next`,
        params: {
            opType: data.opType
        }
    })
}

// 已上架行政区划
export const getAreas = () => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/local-record/local-record-areas'
    })
}

// 获取局部段落
export const getRangeContent = data => {
    return request({
        method: 'POST',
        url: '/knowledgeshow/slzs-paper/ebooks-content-range',
        data,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

export const getImg = id => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/slzs-paper/${id}/cur-periodical-info`
    })
}

export const getPeriodical = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/slzs-paper/periodical-pic-count',
        params
    })
}