<template>
  <div
    class="pages"
    v-loading="loading"
    element-loading-text="电子书加载中···"
    element-loading-background="rgba(0, 0, 0, .1)"
  >
    <div class="book" :class="isMobile ? 'phone' : 'browser'">
      <!-- 目录 -->
      <div class="left" :style="{ width: isShowLeft ? '15%' : '0' }">
        <div class="title">
          <h3>{{ title }}</h3>
        </div>

        <el-tree
          ref="tree"
          accordion
          highlight-current
          :data="EBookData"
          :props="defaultProps"
          node-key="id"
          @node-click="handleNodeClick"
        >
          <span
            slot-scope="{ node }"
            class="custom-tree-node showname"
            :title="node.label"
            v-text="node.label"
          >
          </span>
        </el-tree>
      </div>
      <div class="right" ref="right">
        <img
          ref="zkimg"
          @click="onShowLeft"
          :title="isShowLeft ? '关闭目录' : '展开目录'"
          src="../../assets/image/展开菜单.png"
          class="zk-img"
        />

        <div class="book_box">
          <div class="img_box">
            <!-- <img
              src="../../assets/image/view.png"
              class="view"
              v-if="pageIndex >= 0 && isViewShow"
              @click="viewPic"
            /> -->
            <span></span>
            <el-input
              class="search"
              v-model="keyword"
              @keyup.enter.native="handlerSearch()"
              placeholder="输入关键字"
              v-show="EBookData.length !== 0"
            >
              <template #suffix>
                <img
                  style="cursor: pointer"
                  @click="handlerSearch()"
                  class="img"
                  src="../../assets/image/fzg_ss.png"
                />
              </template>
            </el-input>
            <span></span>
          </div>

          <div class="book" ref="bodycontent" v-show="EBookData.length !== 0">
            <!-- 电子书 -->
            <div
              v-dragscroll
              class="body-content"
              :style="{
                width: pageWidth + 'px',
                height: pageHeight + 'px',
              }"
            >
              <div
                style="z-index: -1; cursor: move"
                class="dropBox"
                ref="dropBox"
                title="右键生成局部段落"
                @contextmenu.prevent="dropclick"
                @mousedown.capture="onMousedown"
              >
                <div class="dropwindow" ref="dropopen" id="dropopen">
                  <div class="drop-list" @click="cancelCon">取消选区</div>
                  <div class="drop-list" @click="copyText">生成段落</div>
                </div>
              </div>
              <div id="magazine" @dblclick="turnZoom" ref="magazine">
                <div
                  v-for="(item, index) in allPages"
                  :key="`test_${index}`"
                  :data-index="index"
                  class="1"
                >
                  <div class="text1" ref="text1">
                    <auth-pic
                      v-if="item.pictureFullName"
                      :auth-src="`/api/fileinfo/image/GetOriImage?fileName=${item.pictureFullName}`"
                      :data-index="index"
                      :data-id="item.id"
                      class="pic"
                    ></auth-pic>
                    <img v-else-if="item.img" class="tempPic" :src="item.img" />
                    <div v-else class="2"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              ref="btn_1"
              @mouseenter="onmouseenter"
              @mouseleave="onmouseleave"
              @click="onLeftClick"
              class="left_btn"
              v-show="searchResList.length > 10 && isBottomShow && isShowImg"
            >
              <i class="el-icon-arrow-left"></i>
            </div>
            <div
              ref="btn_2"
              @click="onRightClick"
              @mouseenter="onmouseenter"
              @mouseleave="onmouseleave"
              class="right_btn"
              v-show="searchResList.length > 10 && isBottomShow && isShowImg"
            >
              <i class="el-icon-arrow-right"></i>
            </div>
            <!-- 缩略图 -->
            <div
              class="box"
              v-show="searchResList.length && isBottomShow && isShowImg"
            >
              <ul
                class="image_box"
                ref="image_box"
                @mouseenter="onmouseenter"
                @mouseleave="onmouseleave"
                @click="onImgClick"
              >
                <li
                  v-for="(v, i) in searchResList"
                  :key="v.id"
                  ref="li"
                  :title="i + 1"
                >
                  <img
                    :src="`/api/fileinfo/image/GetOriImageThumbnail?filename=${v.pictureFullName}`"
                    :data-location="JSON.stringify(v)"
                    alt=""
                  />
                </li>
                <img
                  @load="onload"
                  v-if="searchResList.length"
                  style="display: none"
                  :src="`/api/fileinfo/image/GetOriImageThumbnail?filename=${searchResList[0]?.pictureFullName}`"
                />
              </ul>
            </div>

            <!-- 操作 -->
            <div :class="isMobile ? 'pagination' : 'pagination1'">
              <div
                class="pagination-item el-icon-d-arrow-left"
                @click="firstPage"
              ></div>
              <div
                class="pagination-item el-icon-arrow-left"
                @click="prePage"
              ></div>
              <div class="pagination-input">
                <!-- type="number" -->
                <el-input
                  ref="pageInput"
                  :placeholder="placeholderStr"
                  v-model="paginationNum"
                  @keydown.enter.native="blurOut"
                ></el-input>
                <!-- @keyup.enter.native="blurOut" -->
                <!-- <span>
                  {{ placeholderStr }}
                </span> -->
              </div>
              <div
                class="pagination-item el-icon-arrow-right"
                @click="nextPage"
              ></div>
              <div
                class="pagination-item el-icon-d-arrow-right"
                @click="lastPage"
              ></div>
              <div
                class="pagination-item el-icon-rank"
                title="双击电子书可直接放大缩小"
                @click="turnZoom"
              ></div>
            </div>
          </div>
        </div>

        <div class="text_box" v-show="!loading">
          <div class="text">
            <div class="operation">
              <p style="padding: 5px; font-size: 16px">
                全文基于机器识别，会有少量识别错误
              </p>
              <div style="display: flex">
                <div>书写顺序：</div>
                <el-radio @input="onRadioChange" v-model="radio" label="1"
                  >横排</el-radio
                >
                <el-radio @input="onRadioChange" v-model="radio" label="2"
                  >竖排</el-radio
                >
              </div>
            </div>
            <div :class="[radio === '1' ? 'test2' : 'test1']">
              <span
                id="articleDetailsLeft"
                v-html="highlight(content.leftModelDto?.content)"
              ></span>
              <!-- v-html="content.leftModelDto?.content" -->
              <br />
              <span style="visibility: hidden">☂</span>
              <br />
              <span
                id="articleDetailsRight"
                v-html="highlight(content.rightModelDto?.content)"
              >
              </span>
            </div>
          </div>
          <div class="btn">
            <!-- <el-button @click="qrClick" title="扫描二维码获取文字段落内容"
              >二维码获取</el-button
            >
            <el-button @click="onClick" title="获取文字段落生成的引文文献"
              >引用生成获取</el-button
            > -->
          </div>
        </div>
      </div>
    </div>
    <!-- 引用生成 -->
    <!-- <quote-page
      :quote-dialog-visible.sync="quoteDialogVisible"
      :content="content"
      :info="bookInfo"
    /> -->
    <!-- 二维码获取 -->
    <!-- <qrcode-page
      :qr-code-dialog-visible.sync="qrCodeDialogVisible"
      :downContentUrl="downContentUrl"
    /> -->

    <!-- 局部段落 -->
    <range-dialog
      :range-dialog-visible.sync="rangeDialogVisible"
      :content="rangeContent"
      :info="bookInfo"
    ></range-dialog>

    <div style="z-index: 999999999">
      <el-dialog
        id="dlog"
        :visible.sync="dialogTableVisible"
        @close="onClose"
        :modal="true"
        :close-on-click-modal="false"
      >
        <div
          style="height: 60vh; display: flex; justify-content: center"
          @contextmenu.prevent
        >
          <a
            :href="`/api/fileinfo/image/GetOriImageFangZhi?filename=${pictureFullName}&ocrSource=1`"
            target="_blank"
          >
            <el-image
              @load="onload"
              @error="onerror"
              fit="contain"
              :src="`/api/fileinfo/image/GetOriImageFangZhi?filename=${pictureFullName}&ocrSource=1`"
              style="width: 100%; height: 100%"
            />
          </a>
        </div>
        <div
          class="el-dialog__footer"
          style="display: flex; justify-content: center; align-items: center"
        >
          <el-button @click="Before">上一页</el-button>
          <el-button @click="After">下一页</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import _ from "lodash";
import authPic from "@/components/aut/authPic";
import turn from "@/utils/turn";
import {
  getEBook,
  getEBookContent,
  handlerSearch,
  getPic,
  BeforeOrNext,
  getRangeContent,
  getImg,
  getPeriodical,
} from "../../api/overseaschinese";
import { getBaseData, getTree } from "../../api/overseaschinese";
import $ from "jquery";
// import QuotePage from "./components/quotePage.vue";
// import QrcodePage from "./components/qrcodePage.vue";
import rangeDialog from "./components/rangeContent.vue";

export default {
  components: {
    rangeDialog,
    authPic,
    // QuotePage,
    // QrcodePage,
  },
  data() {
    return {
      eBookHeight: 0,
      eBookWidth: 0,
      isMove: false,
      ocrInfoId: "",
      leftOcrInfoId: "",
      rightOcrInfoId: "",
      pageCount: 0,
      bookInfos: {},
      id: "",
      timer: null,
      isMobile: null, // 默认浏览器环境
      isShowImg: true,
      isDisable: true,
      one: {},
      two: {},
      num: 1,
      tempMessage: "", //复制内容
      isDrawing: true,
      rangeContent: {},
      rangeDialogVisible: false,
      index: 0,
      scale: 0,
      positionObj: {},
      scaleY: 0,
      scaleX: 0,
      naturalWidth: 0,
      naturalHeight: 0,
      proofreadList: [],
      title: "",
      // checkedList: [],
      cutWidth: 0,
      isBottomShow: true,
      isViewShow: false,
      totalPage: "",
      genealogyOCRInfoID: "",
      pictureFullName: "",
      dialogTableVisible: false,
      ebookId: "",
      radio: "2",
      isShowLeft: true,
      // treeExpandData: [],
      pIndex: 0,
      zoom: 0,
      divWidth: 0,
      divHeight: 0,
      oWidth: "",
      oHeight: "",
      locations: [],
      imgIndex: 0,
      searchResList: [],
      sourceID: "",
      downContentUrl: "",
      pageIndex: 1,
      qrCodeDialogVisible: false,
      quoteDialogVisible: false,
      content: {
        rightModelDto: "",
        leftModelDto: "",
      },
      keyword: "", // 搜索关键字
      defaultProps: {
        children: "childList",
        label: "title",
      },
      loading: false,
      originTitle: "", //原封面
      /* 预览电子书 */
      searchData: [], //搜索结果
      pagesData: [], //所有页面
      searchKeyWord: "", //检索关键词
      scaleValue: 1, //缩小 、放大
      paginationNum: null, //跳转到特定页面
      allPages: [], //电子书图片列表
      translateX: 0, //移动值
      transData: [], //检索展示的页面
      scaleIndex: 1,
      /* 电子书 */
      pageWidth: 0,
      pageHeight: 0,
      mPageCount: 0, //电子书总页数数量
      ebookIndex: 0, //当前对应的电子书
      activeId: null, //当前对应的电子书id子级的id
      eBookDialogVisible: false, //修改电子书封面弹窗
      EBookData: [], //电子书列表
      EBookItem: null,
      ePageIndex: 1,
      ePageSize: 50,
      eTotals: 0,
      placeholderStr: "",
      isSingle: false, //是否单面
      ispicType: "", //电子书类型
      outputWatermark: "",
      flag: false,
      bookInfo: {},
      leftCitation: "",
      rightCitation: "",
      rightIndex: "",
      leftIndex: "",
      rightTitle: "",
      leftTitle: "",
      picIndex: "",
      loadingInstance: "",
      options: {
        fullscreen: true,
        lock: true,
        text: "Loading",
        target: "#dlog",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      },
    };
  },
  async created() {
    this.id = this.$route.query?.id;
    this.paperName = this.$route.query.paperName;
    this.keyword = this.$route.query.searchKeyWord?.replace(/&/g, " ");
    this.qs = this.$route.query.qs;
    if (this.id) {
      this.getEBook(this.id, 3);
    } else {
      this.getEBookForName({ qs: this.qs, paperName: this.paperName });
    }
  },
  async mounted() {
    this.isMobile = this._isMobile();
    this.moveleft();
    $(window).bind("keydown", function (e) {
      if (e.keyCode == 37) $("#magazine").turn("previous");
      else if (e.keyCode == 39) $("#magazine").turn("next");
    });
    // window.onload = () => {
    //   let answerRight = document.getElementById("articleDetailsRight");
    //   answerRight.addEventListener("copy", (e) => {
    //     this.setClipboardText(e, "right");
    //   });
    //   let answerLeft = document.getElementById("articleDetailsLeft");
    //   answerLeft.addEventListener("copy", (e) => {
    //     this.setClipboardText(e, "left");
    //   });
    // };
  },
  watch: {
    // locations: {
    //   handler(newVal) {
    //     if (newVal) {
    //       this.renderSearchMask(newVal, this.pIndex);
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    // 初始化成功 电子书加载完毕
    flag(newVal) {
      if (newVal) {
        if (this.keyword) {
          this.handlerSearch();
        }
      }
    },
  },
  beforeDestroy() {
    // window.removeEventListener("copy", this.setClipboardText());
    $("#magazine").turn("destroy").remove();
    window.removeEventListener("keydown", this.moveleft);
  },
  methods: {
    onMousedown(e) {
      e.preventDefault();
      e.stopPropagation();
      let that = this;
      let dropbox = that.$refs.dropBox;
      let drop = {};
      drop.x = e.clientX;
      drop.y = e.clientY;
      let left = dropbox.style.left;
      let top = dropbox.style.top;
      let dropboxWidth = parseFloat(dropbox.style.width);
      let dropboxHeight = parseFloat(dropbox.style.height);
      this.isMove = true;

      let width =
        parseFloat(dropbox.style.left) > that.eBookWidth ? that.eBookWidth : 0;
      function move(e) {
        e.preventDefault();
        e.stopPropagation();
        dropbox.style.left = parseFloat(left) + (e.clientX - drop.x) + "px";
        dropbox.style.top = parseFloat(top) + (e.clientY - drop.y) + "px";

        if (parseFloat(dropbox.style.left) - width <= 0) {
          dropbox.style.left = 2 + width + "px";
        } else if (
          parseFloat(dropbox.style.left) + dropboxWidth - width >
          that.eBookWidth - 2
        ) {
          dropbox.style.left =
            that.eBookWidth - 2 - dropboxWidth + width + "px";
        }
        if (parseFloat(dropbox.style.top) <= 0) {
          dropbox.style.top = 2 + "px";
        } else if (
          parseFloat(dropbox.style.top) + dropboxHeight >
          that.eBookHeight - 2
        ) {
          dropbox.style.top = that.eBookHeight - 2 - dropboxHeight + "px";
        }
      }

      function stopMove(e) {
        e.preventDefault();
        e.stopPropagation();
        that.isMove = false;
        that.positionObj.x = parseFloat(dropbox.style.left) - width;
        that.positionObj.y = parseFloat(dropbox.style.top);
        document.removeEventListener("mousemove", move);
        document.removeEventListener("mouseup", stopMove);
      }

      if (this.isMove) {
        // 将事件监听器添加到document上
        document.addEventListener("mousemove", move);
        document.addEventListener("mouseup", stopMove);
      }

      return false;
    },

    async pageTurn(params) {
      try {
        const res = await pageTurn(params);
        this.allPages.push(res.rightModelDto, res.leftModelDto);
      } catch (error) {}
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    async getImg(id) {
      const res = await getImg(id);
      this.allPages.push(res.rightModelDto, res.leftModelDto);
      this.HangChang();
    },
    // 取消选区
    cancelCon() {
      this.one = {};
      this.two = {};
      this.num = 1;
      let dropopen = this.$refs.dropopen;
      dropopen.style.display = `none`;
      let dropbox = this.$refs.dropBox;
      dropbox.style.left = `-1px`;
      dropbox.style.top = 0;
      dropbox.style.width = 0;
      dropbox.style.height = 0;
      dropbox.style.zIndex = `-1`;
    },
    // 局部段落
    async copyText() {
      const loading = this.$loading({
        lock: true,
        target: ".body-content",
        text: "加载中...",
        spinner: "el-icon-loading",
      });
      try {
        const res = await getRangeContent({
          ocrInfoId: this.ocrInfoId,
          region: {
            location: {
              x: parseInt(this.positionObj.x / this.scaleX),
              y: parseInt(this.positionObj.y / this.scaleY),
            },
            size: {
              width: parseInt(this.positionObj.width / this.scaleX) - 20,
              height: parseInt(this.positionObj.height / this.scaleY) - 20,
            },
            x: parseInt(this.positionObj.x / this.scaleX),
            y: parseInt(this.positionObj.y / this.scaleY),
            width: parseInt(this.positionObj.width / this.scaleX) - 20,
            height: parseInt(this.positionObj.height / this.scaleY) - 20,
          },
        });
        this.rangeContent = res;
        this.$refs.dropopen.style.display = "none";
        if (!this.rangeContent.content)
          return this.$message.info("暂无可生成的内容");
        // const pattern = /\[[^\]]*\d[^\]]*\]/g;
        // this.rangeContent.content = this.rangeContent.content.replace(
        //   pattern,
        //   "<img title='$&' alt='$&' src='http://112.111.62.151:9000/api/proofread/WordBuilder/GetWordImageByCode?code=$&' style='width: 23px; height: 23px'/><span style='display: none'>$&</span>"
        // );
        this.rangeDialogVisible = true;
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    // 右击画框
    dropclick(e) {
      let rightbox = this.$refs.dropopen;
      rightbox.style.right = "";
      rightbox.style.left = "";

      rightbox.style.top = e.offsetY + "px";
      if (this.positionObj.x + e.offsetX + 150 >= this.pageWidth / 2) {
        rightbox.style.right = this.positionObj.width - e.offsetX + "px";
      } else {
        rightbox.style.left = e.offsetX + "px";
      }
      rightbox.style.display = "flex";
    },
    // 画框
    moveleft(e) {
      let that = this;
      let ele = that.$refs.magazine;
      ele.addEventListener("mousedown", (e) => {
        if (e.button !== 0) return;
        this.$refs.dropopen.style.display = "none";

        this.isShowImg = false;
        // 移动
        // if (e.ctrlKey) {
        if (this.isDisable) {
          e?.stopPropagation();
          that.cancelCon();
          that.isDrawing = false;
          // 减去边框
          // that.scale = (e.target.offsetWidth - 2) / that.naturalWidth;

          // that.scaleX =
          //   e.target?.querySelector("img")?.width /
          //   e.target?.querySelector("img")?.naturalWidth;
          // that.scaleY =
          //   e.target?.querySelector("img")?.height /
          //   e.target?.querySelector("img")?.naturalHeight;
          that.ocrInfoId = e.target.dataset.id;
          that.scaleX = e.target?.width / e.target?.naturalWidth;
          that.scaleY = e.target?.height / e.target?.naturalHeight;
          // console.log(e.target);
          // console.log(that.scaleX);
          // console.log(e.offsetX);
          //获取鼠标开始拖动的起始位置
          let a = {};
          a.x = e.offsetX;
          a.y = e.offsetY;

          let offsetWidth = e.target.offsetWidth;
          let offsetHeight = e.target.offsetHeight;

          this.eBookWidth = offsetWidth;
          this.eBookHeight = offsetHeight;
          let index = e.target.dataset?.index;
          let width = "";
          if (index && index % 2 != 0) {
            width = e.target.offsetWidth;
          } else if (!index) {
            return;
          } else {
            width = 0;
          }
          this.index = index - 2;

          //鼠标按下移动时动态获取鼠标位置
          ele.addEventListener("mousemove", move);

          function move(e) {
            let element = that.$refs.dropBox;
            let offsetX = e.offsetX;
            let offsetY = e.offsetY;

            element.style.left = a.x + width + "px";
            element.style.top = a.y + "px";
            element.style.border = `1px dashed red`;
            element.style.position = `absolute`;
            element.style.zIndex = `999999`;

            // if (e.offsetX < a.x || e.offsetY < a.y) {
            //   if (e.offsetX <= 2 || e.offsetY <= 2) {
            //     that.positionObj = {
            //       x: offsetX,
            //       y: e.offsetY,
            //       width: Math.abs(offsetX - a.x),
            //       height: Math.abs(e.offsetY - a.y),
            //     };
            //     element.style.width = Math.abs(offsetX - a.x) - 5 + "px";
            //     element.style.height = Math.abs(e.offsetY - a.y) - 5 + "px";
            //     element.style.left = e.offsetX + width + "px";
            //     element.style.top = e.offsetY + "px";
            //     // element.style.border = `1px dashed red`;
            //     // element.style.position = `absolute`;
            //     // element.style.zIndex = `999999`;

            //     ele.removeEventListener("mousemove", move);
            //   } else {
            //     that.positionObj = {
            //       x: offsetX,
            //       y: e.offsetY,
            //       width: Math.abs(offsetX - a.x),
            //       height: Math.abs(e.offsetY - a.y),
            //     };
            //     element.style.width = Math.abs(offsetX - a.x) + "px";
            //     element.style.height = Math.abs(e.offsetY - a.y) + "px";
            //     let numX = 0;
            //     let numY = 0;
            //     let left = 0;
            //     let top = 0;
            //     if (e.offsetX < a.x && e.offsetY < a.y) {
            //       numX = numY = 5;
            //       left = e.offsetX + width + numX + "px";
            //       top = e.offsetY + numY + "px";
            //     } else if (e.offsetX < a.x && !e.offsetY < a.y) {
            //       numX = 5;
            //       numY = 0;
            //       left = a.x + (e.offsetX - a.x) + numX + "px";
            //       top = a.y + numY + "px";
            //     } else {
            //       numX = 0;
            //       numY = 5;
            //       left = a.x;
            //       +numX + "px";
            //       top = a.y + (e.offsetY - a.y) + numY + "px";
            //     }
            //     element.style.left = left;
            //     element.style.top = top;
            //     // element.style.border = `1px dashed red`;
            //     // element.style.position = `absolute`;
            //     // element.style.zIndex = `999999`;
            //   }
            // } else

            if (e.offsetX >= offsetWidth - 5) {
              ele.removeEventListener("mousemove", move);
              offsetX = offsetWidth;
              that.positionObj = {
                x: a.x,
                y: a.y,
                width: offsetX - a.x,
                height: e.offsetY - a.y,
              };
              element.style.width = offsetX - a.x - 5 + "px";
              element.style.height = e.offsetY - a.y - 5 + "px";
              // element.style.left = a.x + width + "px";
              // element.style.top = a.y + "px";
              // element.style.border = `1px dashed red`;
              // element.style.position = `absolute`;
              // element.style.zIndex = `999999`;
              that.isDrawing = true;
            } else if (e.offsetY >= offsetHeight - 10) {
              ele.removeEventListener("mousemove", move);
              offsetY = offsetHeight;
              that.positionObj = {
                x: a.x,
                y: a.y,
                width: e.offsetX - a.x,
                height: offsetY - a.y,
              };
              element.style.width = e.offsetX - a.x - 5 + "px";
              element.style.height = offsetY - a.y - 5 + "px";
              // element.style.left = a.x + width + "px";
              // element.style.top = a.y + "px";
              // element.style.border = `1px dashed red`;
              // element.style.position = `absolute`;
              // element.style.zIndex = `999999`;
              that.isDrawing = true;
            } else {
              that.positionObj = {
                x: a.x,
                y: a.y,
                width: offsetX - a.x,
                height: e.offsetY - a.y,
              };
              element.style.width = offsetX - a.x - 5 + "px";
              element.style.height = e.offsetY - a.y - 5 + "px";
              // element.style.left = a.x + width + "px";
              // element.style.top = a.y + "px";
              // element.style.border = `1px dashed red`;
              // element.style.position = `absolute`;
              // element.style.zIndex = `999999`;
            }
          }

          //鼠标放下时，停止生成画框
          ele.addEventListener("mouseup", function () {
            ele.removeEventListener("mousemove", move);
            that.isDrawing = true;
            that.isShowImg = true;
          });
        }
      });
    },
    async getBaseData(id) {
      const res = await getBaseData(id);
      // this.baseData = res;
      // this.getImg(res.ocrInfoItems[0].documentOCRInfoId);
    },
    // 高亮
    highlight(text) {
      if (!text) return;
      let tempList = [];
      if (this.searchResList.length) {
        for (let i = 0; i < this.searchResList.length; i++) {
          for (let j = 0; j < this.searchResList[i].words.length; j++) {
            tempList.push(this.searchResList[i].words[j].word);
          }
          tempList = [...new Set(tempList)];
          if (tempList.length === this.keyword.length) {
            break;
          }
        }
      }
      let str = text;
      if (this.keyword) {
        const req = new RegExp(`(${tempList.join("|")})`, "gi");
        // 字符串的替换
        str = text.replace(req, '<span style="color:red">$1</span>');
      }
      return str;
    },
    expandParents(node) {
      node.expanded = true;
      if (node.parent) {
        this.expandParents(node.parent);
      }
    },
    async Before() {
      this.loadingInstance = Loading.service(this.options);
      try {
        const res = await BeforeOrNext({
          id: this.genealogyOCRInfoID,
          opType: 0,
        });
        this.pictureFullName = res.pictureFullName;
        this.genealogyOCRInfoID = res.genealogyOCRInfoID;
      } catch (e) {
        if (e.response.status === "403") {
          this.$message.info("暂无数据");
        }
      } finally {
        this.loadingInstance.close();
      }
    },
    async After() {
      this.loadingInstance = Loading.service(this.options);
      try {
        const res = await BeforeOrNext({
          id: this.genealogyOCRInfoID,
          opType: 1,
        });
        this.genealogyOCRInfoID = res.genealogyOCRInfoID;
        this.pictureFullName = res.pictureFullName;
      } catch (e) {
        if (e.response.status === "403") {
          this.$message.info("暂无数据");
        }
      } finally {
        this.loadingInstance.close();
      }
    },
    onClose() {
      this.dialogTableVisible = false;
      this.isBottomShow = true;
      // this.pictureFullName = "";
    },
    onload() {
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        this.loadingInstance.close();
      });
    },
    onerror() {
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        this.loadingInstance.close();
      });
    },
    async viewPic() {
      this.isBottomShow = false;
      if (this.pageIndex <= 0) return;
      try {
        const res = await getPic({
          ebookId: this.ebookId,
          pageIndex: this.pageIndex,
        });
        this.pictureFullName = res.pictureFullName;
        this.genealogyOCRInfoID = res.genealogyOCRInfoID;
        this.dialogTableVisible = true;
      } catch (error) {}
    },
    // 判断是否在最后
    validationEnd(str, appoint) {
      str = str.toLowerCase(); //不区分大小写：全部转为小写后进行判断

      var start = str.length - appoint.length; //相差长度=字符串长度-特定字符长度
      var char = str.substr(start, appoint.length); //将相差长度作为开始下标，特定字符长度为截取长度

      if (char == appoint) {
        //两者相同，则代表验证通过
        return true;
      }
      return false;
    },
    // 引用生成
    onClick() {
      if (this.content.rightModelDto.content) {
        this.content.rightModelDto.content = this.content.rightModelDto.content
          .replace(/︵/g, "(")
          .replace(/︶/g, ")")
          .replace(/︽/g, "《")
          .replace(/︾/g, "》")
          .replace(/﹃/g, "『")
          .replace(/﹄/g, "』");
      }
      if (this.content.leftModelDto.content) {
        this.content.leftModelDto.content = this.content.leftModelDto.content
          .replace(/︵/g, "(")
          .replace(/︶/g, ")")
          .replace(/︽/g, "《")
          .replace(/︾/g, "》")
          .replace(/﹃/g, "『")
          .replace(/﹄/g, "』")
          .replace(/﹁/g, "「")
          .replace(/﹂/g, " 」");
      }
      this.quoteDialogVisible = true;
    },
    // 复制携带版权信息
    setClipboardText(event, position) {
      let leftData = this.content.leftModelDto;
      let rightData = this.content.rightModelDto;
      event?.preventDefault(); //阻止元素发生默认的行为
      const node = document.createElement("div");
      node.appendChild(window.getSelection().getRangeAt(0).cloneContents());
      // this.leftCitation = `[序号]${
      //   leftData.contributor ? leftData.contributor + "." : ""
      // }${leftData.title}[M].${leftData.placeOfPublication}.${
      //   leftData.publisher ? leftData.publisher + "." : ""
      // }${leftData.dateOfPublication}:第${leftData.actualPageIndex}页;`;

      // this.rightCitation = `[序号]${
      //   rightData.contributor ? rightData.contributor + "." : ""
      // }${rightData.title}[M].${rightData.placeOfPublication}.${
      //   rightData.publisher ? rightData.publisher + "." : ""
      // }${rightData.dateOfPublication}:第${rightData.actualPageIndex}页;`;
      this.rightCitation = "";
      this.leftCitation = "";
      let htmlData;
      let textData;
      if (position === "left" && !node.innerText.includes("☂")) {
        htmlData =
          "<div>" +
          // node.innerHTML.replace(/\n/g, "") +
          node.innerText.replace(/\n/g, "") +
          `${this.leftCitation}` +
          "</div>";
        textData =
          // window.getSelection().getRangeAt(0).replaceAll("\n", "") +
          node.innerText.replace(/\n/g, "") +
          "\n" +
          "\n" +
          "---------------------------" +
          "\n" +
          "\n" +
          `${this.leftCitation}`;
      }
      if (
        position === "left" &&
        node.innerText.includes("☂") &&
        !this.validationEnd(node.innerText, "☂")
      ) {
        htmlData =
          "<div>" +
          // node.innerHTML.replace(/\n/g, "") +
          node.innerText
            .replace(/\n/g, "")
            .replace(
              /☂/,
              "\n" +
                "---------------------------" +
                "\n" +
                this.leftCitation +
                "\n" +
                "\n"
            ) +
          "\n" +
          `${this.rightCitation}` +
          "</div>";
        textData =
          // window.getSelection().getRangeAt(0).replaceAll("\n", "") +
          node.innerText.replace(/\n/g, "").replace(
            /☂/,

            "\n" +
              "---------------------------" +
              "\n" +
              this.leftCitation +
              "\n" +
              "\n"
          ) +
          "\n" +
          "---------------------------" +
          "\n" +
          `${this.rightCitation}`;
      } else if (
        position === "right" &&
        node.innerText.includes("☂") &&
        !this.validationEnd(node.innerText, "☂")
      ) {
        htmlData =
          "<div>" +
          // node.innerHTML.replace(/\n/g, "") +
          node.innerText.replace(/\n/g, "").replace(
            /☂/,

            "\n" +
              "---------------------------" +
              "\n" +
              this.rightCitation +
              "\n" +
              "\n"
          ) +
          "\n" +
          `${this.leftCitation}` +
          "</div>";
        textData =
          // window.getSelection().getRangeAt(0).replaceAll("\n", "") +
          node.innerText.replace(/\n/g, "").replace(
            /☂/,

            "\n" +
              "---------------------------" +
              "\n" +
              this.rightCitation +
              "\n" +
              "\n"
          ) +
          "\n" +
          "\n" +
          "---------------------------" +
          "\n" +
          "\n" +
          `${this.leftCitation}`;
      } else if (position === "right" && !node.innerText.includes("☂")) {
        htmlData =
          "<div>" +
          // node.innerHTML.replace(/\n/g, "") +
          node.innerText.replace(/\n/g, "") +
          `${this.rightCitation}` +
          "</div>";
        textData =
          // window.getSelection().getRangeAt(0).replaceAll("\n", "") +
          node.innerText.replace(/\n/g, "") +
          "\n" +
          "\n" +
          "---------------------------" +
          "\n" +
          "\n" +
          `${this.rightCitation}`;
      } else if (
        position === "left" &&
        node.innerText.includes("☂") &&
        this.validationEnd(node.innerText, "☂")
      ) {
        htmlData =
          "<div>" +
          // node.innerHTML.replace(/\n/g, "") +
          node.innerText
            .replace(/\n/g, "")
            .replace(
              /☂/,
              "\n" +
                "---------------------------" +
                "\n" +
                this.leftCitation +
                "\n" +
                "\n"
            ) +
          "</div>";
        textData =
          // window.getSelection().getRangeAt(0).replaceAll("\n", "") +
          node.innerText
            .replace(/\n/g, "")
            .replace(
              /☂/,
              "\n" +
                "---------------------------" +
                "\n" +
                this.leftCitation +
                "\n" +
                "\n"
            );
      } else if (
        position === "right" &&
        node.innerText.includes("☂") &&
        node.innerText[0] === "☂"
      ) {
        htmlData =
          "<div>" +
          // node.innerHTML.replace(/\n/g, "") +
          node.innerText
            .replace(/\n/g, "")
            .replace(
              /☂/,
              "\n" +
                "---------------------------" +
                "\n" +
                this.rightCitation +
                "\n" +
                "\n"
            ) +
          "</div>";
        textData =
          // window.getSelection().getRangeAt(0).replaceAll("\n", "") +
          node.innerText
            .replace(/\n/g, "")
            .replace(
              /☂/,
              "\n" +
                "---------------------------" +
                "\n" +
                this.rightCitation +
                "\n" +
                "\n"
            );
      }

      if (event.clipboardData) {
        event.clipboardData.setData("text/html", htmlData);
        //setData(剪贴板格式, 数据) 给剪贴板赋予指定格式的数据。返回 true 表示操作成功。
        event.clipboardData.setData("text/plain", textData);
      } else if (window.clipboardData) {
        //window.clipboardData的作用是在页面上将需要的东西复制到剪贴板上，提供了对于预定义的剪贴板格式的访问，以便在编辑操作中使用。
        return window.clipboardData.setData("text", textData);
      }
    },
    // 改变书写顺序
    onRadioChange(e) {
      if (this.content.rightModelDto.content) {
        this.content.rightModelDto.content = this.content.rightModelDto.content
          .replace(/︵/g, "(")
          .replace(/︶/g, ")")
          .replace(/︽/g, "《")
          .replace(/︾/g, "》")
          .replace(/﹃/g, "『")
          .replace(/﹄/g, "』")
          .replace(/﹁/g, "「")
          .replace(/﹂/g, " 」");
        // .replace(/︹/g, " ")
        // .replace(/︺/g, " ");
      }
      if (this.content.leftModelDto.content) {
        this.content.leftModelDto.content = this.content.leftModelDto.content
          .replace(/︵/g, "(")
          .replace(/︶/g, ")")
          .replace(/︽/g, "《")
          .replace(/︾/g, "》")
          .replace(/﹃/g, "『")
          .replace(/﹄/g, "』")
          .replace(/﹁/g, "「")
          .replace(/﹂/g, " 」");
        // .replace(/︹/g, " ")
        // .replace(/︺/g, " ");
      }
      this.radio = e;
    },
    // 目录展开收起
    onShowLeft() {
      if (this.isShowLeft) {
        this.isShowLeft = !this.isShowLeft;
        this.$refs.right.style.width = "100%";
        this.$refs.zkimg.style = "transform:  rotate(0deg);";
      } else {
        this.isShowLeft = !this.isShowLeft;
        this.$refs.right.style.width = "85%";
        this.$refs.zkimg.style = "transform:  rotate(180deg);";
      }
    },
    convertRect0(sRect) {
      var values = sRect.split(",");
      if (values.length == 4) {
        var rect = {
          x: parseInt(values[0]),
          y: parseInt(values[1]),
          width: parseInt(values[2]),
          height: parseInt(values[3]),
        };
        return rect;
      }
      return { x: 0, y: 0, width: 0, height: 0 };
    },
    renderSearchMask(data, pageIndex) {
      let node = document.querySelector(".ebook-search-page");
      if (node) {
        node.remove();
      }
      let that = this;
      if (
        data &&
        pageIndex > 0 &&
        $(".page-wrapper[page=" + pageIndex + "]", "#magazine")
      ) {
        var $page = $(".page-wrapper[page=" + pageIndex + "]", "#magazine");
        if (!$page.find("img").height() && !$page.find("img").width()) {
          clearInterval(this.timer);
          this.timer = setInterval(() => {
            this.renderSearchMask(data, pageIndex);
          }, 1000);
        } else {
          clearInterval(this.timer);
          var wr = $page.find("img").width() / (data.width - this.cutWidth * 2);
          var hr = $page.find("img").height() / data.height;

          if ($page.length === 1) {
            var $mask = $(".ebook-search-page", $page);
            if ($mask.length == 1) {
              $mask.remove();
            }
            var html = '<div class="ebook-search-page">';
            html += "</div>";
            var $sp = $(html);
            var rect;
            html = "";
            let self = this;

            $.each(data.words, function () {
              if (
                this.location.x &&
                this.location.y &&
                this.location.width &&
                this.location.height
              ) {
                rect = {
                  x: parseInt(this.location.x),
                  y: parseInt(this.location.y),
                  width: parseInt(this.location.width),
                  height: parseInt(this.location.height),
                };
              } else {
                rect = { x: 0, y: 0, width: 0, height: 0 };
              }
              // if (!data.isSingle) {
              //   if (this.pageIndex % 2 == 0) {
              //     rect.x -= data.width / 2;
              //   } else {
              //     if (that.EBookData[0].PageWidth == 4252) {
              //     } else {
              //       rect.x -= self.cutWidth;
              //     }
              //   }
              // }
              rect.x *= wr;
              rect.y *= hr;
              rect.width *= wr;
              rect.height *= hr;
              html =
                '<a class="ebook-search-mask" " title="' +
                this.word +
                '" style="display:inline-block;position: absolute;background: rgba(255, 255, 0, .5);left:' +
                rect.x +
                "px;top:" +
                rect.y +
                "px;width:" +
                rect.width +
                "px;height:" +
                rect.height +
                'px"></a>';
              $(html).data("data-bound", rect).appendTo($sp);
            });
            $sp.appendTo($page);
          }
        }
      }
    },
    onload(e) {
      this.oWidth = e.naturalWidth;
      this.oHeight = e.naturalHeight;
    },
    // 点击搜索结果
    async onImgClick(e) {
      let target = e.target;
      if (target.nodeName === "IMG") {
        // let node = document.querySelector(".ebook-search-page");
        // node.remove();
        this.locations = JSON.parse(target.dataset.location);
        await this.getPeriodical({
          curOrderby: this.locations.ebookPage,
        });
        $("#magazine").turn("page", this.locations.ebookPage + 3);
        this.renderSearchMask(this.locations, this.locations.ebookPage + 3);
      }
    },
    // 左箭头
    onLeftClick() {
      if (this.imgIndex === 0) return this.$message.info("已加载第一条");
      this.imgIndex--;
      this.$refs.image_box.style.transition = "left .5s ease 0s";
      this.$refs.image_box.style.left = this.imgIndex * 70 + "px";
    },
    // 右箭头
    onRightClick() {
      if (this.imgIndex >= this.searchResList.length - 10)
        return this.$message.info("已加载最后一条");
      this.imgIndex++;
      this.$refs.image_box.style.transition = "left .5s ease 0s";
      this.$refs.image_box.style.left = -this.imgIndex * 70 + "px";
    },
    // 搜索
    async handlerSearch() {
      const loading = this.$loading({
        lock: true,
        target: ".body-content",
        text: "加载中...",
        spinner: "el-icon-loading",
      });
      try {
        const res = await handlerSearch({
          beginCatalogueInfoId: this.beginCatalogueInfoId,
          endCatalogueInfoId: this.endCatalogueInfoId,
          key: this.keyword.trim(),
        });
        if (!res.length) return this.$message.info("暂无结果");
        // 根据 pageIndex 去重
        // let hash = {};
        // this.searchResList = res.reduce((prev, next) => {
        //   hash[next.pageIndex]
        //     ? ""
        //     : (hash[next.pageIndex] = true && prev.push(next));
        //   return prev;
        // }, []);
        this.searchResList = res;
        this.locations = this.searchResList[0];
        await this.getPeriodical({
          curOrderby: this.searchResList[0].ebookPage,
        });
        $("#magazine").turn("page", this.searchResList[0].ebookPage + 3);
        this.renderSearchMask(
          this.locations,
          this.searchResList[0].ebookPage + 3
        );
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    qrClick() {
      this.downContentUrl =
        "http://" +
        window.location.host +
        "/phone?rightOcrInfoId=" +
        this.rightOcrInfoId +
        "&leftOcrInfoId=" +
        this.leftOcrInfoId;
      this.qrCodeDialogVisible = true;
    },
    onmouseenter() {
      this.$refs.btn_1.style.opacity = "1";
      this.$refs.btn_2.style.opacity = "1";
    },
    onmouseleave() {
      this.$refs.btn_1.style.opacity = "0.1";
      this.$refs.btn_2.style.opacity = "0.1";
    },
    async getEBookContent(leftId, rightId) {
      try {
        var loading = Loading.service({
          fullscreen: true,
          lock: true,
          text: "加载中……",
          target: ".text",
          spinner: "el-icon-loading",
        });
        const res = await getEBookContent({
          leftOcrInfoId: leftId,
          rightOcrInfoId: rightId,
        });
        this.content = res;

        // 异体字转换
        // const pattern = /\[[^\]]*\d[^\]]*\]/g;
        // if (this.content.rightModelDto.content) {
        //   this.content.rightModelDto.content =
        //     this.content.rightModelDto.content.replace(
        //       pattern,
        //       "<img title='$&' alt='$&' src='http://112.111.62.151:9000/api/proofread/WordBuilder/GetWordImageByCode?code=$&' style='width: 23px; height: 23px'/><span style='display: none'>$&</span>"
        //     );
        // }
        // if (this.content.leftModelDto.content) {
        //   this.content.leftModelDto.content =
        //     this.content.leftModelDto.content.replace(
        //       pattern,
        //       "<img title='$&' alt='$&' src='http://112.111.62.151:9000/api/proofread/WordBuilder/GetWordImageByCode?code=$&' style='width: 23px; height: 23px'/><span style='display: none'>$&</span>"
        //     );
        // }

        // 高亮搜索
        // if (this.keyword && this.searchResList.length) {
        //   let tempList = [];
        //   for (let i = 0; i < this.searchResList.length; i++) {
        //     for (let j = 0; j < this.searchResList[i].words.length; j++) {
        //       tempList.push(this.searchResList[i].words[j].word);
        //     }
        //     tempList = [...new Set(tempList)];
        //     if (tempList.length === this.keyword.length) {
        //       break;
        //     }
        //   }
        //   const req = new RegExp(`(${tempList.join("|")})`, "gi");
        //   // 字符串的替换
        //   if (this.content.rightModelDto.content) {
        //     this.content.rightModelDto.content =
        //       this.content.rightModelDto.content.replace(
        //         req,
        //         '<span style="color:red">$1</span>'
        //       );
        //   }
        //   if (this.content.leftModelDto.content) {
        //     this.content.leftModelDto.content =
        //       this.content.leftModelDto.content.replace(
        //         req,
        //         '<span style="color:red">$1</span>'
        //       );
        //   }
        // }

        this.rightIndex = res?.rightModelDto?.actualPageIndex;
        this.leftIndex = res?.leftModelDto?.actualPageIndex;
        this.rightTitle = res?.rightModelDto?.documentTitle;
        this.leftTitle = res?.leftModelDto?.documentTitle;

        if (res.rightModelDto?.content) {
          this.content.rightModelDto.content = res.rightModelDto.content
            .replace(/︵/g, "(")
            .replace(/︶/g, ")")
            .replace(/︽/g, "《")
            .replace(/︾/g, "》")
            .replace(/﹃/g, "『")
            .replace(/﹄/g, "』");
        }
        if (res.leftModelDto?.content) {
          this.content.leftModelDto.content = res.leftModelDto.content
            .replace(/︵/g, "(")
            .replace(/︶/g, ")")
            .replace(/︽/g, "《")
            .replace(/︾/g, "》")
            .replace(/﹃/g, "『")
            .replace(/﹄/g, "』")
            .replace(/﹁/g, "「")
            .replace(/﹂/g, " 」");
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    async handleNodeClick(e) {
      let index = await this.getPeriodical({ curCatalogueInfoId: e.id });
      $("#magazine").turn("page", index + 2);
    },
    async getPeriodical(query) {
      const res = await getPeriodical({
        beginCatalogueInfoId: this.beginCatalogueInfoId,
        endCatalogueInfoId: this.endCatalogueInfoId,
        ...query,
      });

      if (this.pageCount != res.totalCount) {
        this.pageCount = res.totalCount;
        // 封面 空白页 封底
        if (res.totalCount % 2 === 0) {
          this.allPages = new Array(res.totalCount + 4).fill({});
        } else {
          this.allPages = new Array(res.totalCount + 3).fill({});
        }
        this.allPages[0] = {
          img: require("../../assets/华侨报刊大系封面.jpg"),
        };
        this.allPages[this.allPages.length - 1] = {
          img: require("../../assets/华侨报刊大系封底.jpg"),
        };
        await this.HangChang();
      }

      if (res.items[0].ebookPage - 1 > 1) {
        this.allPages.splice(
          2,
          res.items[0].ebookPage - 1 + 2,
          ...new Array(res.items[0].ebookPage - 1 + 2).fill({})
        );
      }

      this.allPages.splice(
        res.items[0].ebookPage - 1 + 2,
        res.items.length,
        ...res.items
      );
      return res.items[0].ebookPage;
    },
    /* turn初始化 */
    async turnInit(item, callback) {
      // var initImgList = [];
      // if (item.pages.length > 20) {
      //   initImgList = item.pages.slice(0, 20);
      // } else {
      //   initImgList = item.pages;
      // }
      this.isViewShow = true;
      let that = this;
      // this.allPages.push(
      //   "/api/fileinfo/ebook/GetEBookFaceForDynamic?path=" + item.facePath
      // );
      // if (that.ispicType == "0" || this.isSingle) {
      //   this.allPages.push("");
      // }

      $("#magazine").turn("center");
      $("#magazine").turn("page");

      this.$nextTick(() => {
        $("#magazine").turn({
          display: "double",
          acceleration: false,
          gradients: true,
          autoCenter: true,
          duration: 1000,
          shadows: true,
          direction: "rtl",
          page: 1,
          // cover: false,
          pages: that.pageCount,
          when: {
            turning: function (event, page, view) {
              // 清除选框
              that.cancelCon();
            },
            turned: async function (e, page, view) {
              await that.getPeriodical({
                curOrderby: page - 1,
              });
              let index = $(this).turn("view")[0] - 1;
              if (index != -1 && index <= that.pageCount + 1) {
                that.leftOcrInfoId = that.allPages[index].id;
                that.rightOcrInfoId = that.allPages[index + 1].id;
                that.getEBookContent(
                  that.allPages[index].id,
                  that.allPages[index + 1].id
                );
              }

              that.pageIndex = $(this).turn("view")[0] - 1;
              that.totalPage = $("#magazine").turn("pages") - 2;
              if ($(this).turn("view")[0] == "0") {
                that.placeholderStr = "封面";
              } else if (
                $(this).turn("view")[0] + 1 >=
                $("#magazine").turn("pages")
              ) {
                that.placeholderStr = "封底";
              } else {
                that.placeholderStr =
                  $(this).turn("view")[0] - 1 + " / " + that.totalPage;
              }

              let pagesArray = $(this).turn("view");

              pagesArray.forEach((pageitem) => {
                that.searchData.forEach((titem) => {
                  if (pageitem > 2 && pageitem - 3 == titem.pageIndex) {
                    $(
                      ".page-wrapper[page=" +
                        (titem.pageIndex + 3) +
                        "]>.ebook-search-page",
                      "#magazine"
                    ).remove();
                    var $page = $(
                      ".page-wrapper[page=" + (titem.pageIndex + 3) + "]",
                      "#magazine"
                    );
                    if ($page.length == 1) {
                      var html = '<div class="ebook-search-page">';
                      titem.words.forEach((sitem, index) => {
                        let rect = {
                          location: JSON.parse(JSON.stringify(sitem.location)),
                        };
                        if (titem.pageIndex % 2 == 0) {
                          rect.location.left -= titem.width / 2;
                        } else {
                          rect.location.left -= 250;
                        }
                        html +=
                          '<a class="ebook-search-mask" title="' +
                          sitem.word +
                          '"style="left:' +
                          Math.floor(rect.location.left * that.scaleIndex) +
                          "px;top:" +
                          Math.floor(rect.location.top * that.scaleIndex) +
                          "px;width:" +
                          Math.floor(rect.location.width * that.scaleIndex) +
                          "px;height:" +
                          Math.floor(rect.location.height * that.scaleIndex) +
                          'px">' +
                          "</a>";
                      });
                      html += "</div>";
                      $(html).appendTo($page);
                    }
                  }
                });
              });
            },
            missing: function (e, pages) {
              for (let i = 0; i < pages.length; i++) that.addPage(pages[i]);
            },
            start: function (e) {
              that.isDisable = false;
            },
            end: function (e) {
              that.isDisable = true;
              that.isShowImg = true;
            },
          },
        });
        if (callback && $.isFunction(callback)) {
          callback();
        }
        this.flag = true;
      });
    },
    // 清除搜索
    cleanKeyword() {
      this.searchResList = [];
      this.keyword = "";
      this.$router.push({
        path: "/ebook",
        query: {
          sourceID: this.$route.query.sourceID,
          bookId: this.$route.query.bookId,
        },
      });
    },
    addPage(pageIndex) {
      console.log(pageIndex, " pageIndex");
      if (pageIndex <= this.mPageCount) {
        var element = $(
          `<div><div data-index='${pageIndex}' style='margin: auto;border-right:2px solid #ccc ;background-size: 100% 100%;width: 100%;height: 100%;background-color: #fff;text-align: center;'></div></div>`
        );
        if (pageIndex == this.mPageCount - 1) {
          if (this.EBookItem.picType == "0") {
            var element = $(
              "<div><div style='margin: auto;background-size: 100% 100%;width: 100%;height: 100%;background-color: #fff;text-align: center;'></div></div>"
            );
          } else {
          }
          // 封底
        } else if (pageIndex == this.mPageCount) {
          var element = $(
            `<div><div data-index='${pageIndex}' style='margin: auto;border-right:2px solid #ccc ;background-size: 100% 100%;width: 100%;height: 100%;background-color: #fff;'><img  src=/api/fileinfo/ebook/GetEBookBackFace?path=` +
              this.EBookItem.facePath +
              " style='height: 100% ; width: 100%;margin: auto;pointer-events: none;user-select: none;'/></div></div>"
          );
        } else {
          if (this.isSingle) {
            var element = $(
              `<div><div data-index='${pageIndex}' style='margin: auto;border-right:2px solid #ccc ;background-size: 100% 100%;width: 100%;height: 100%;background-color: #fff;'><img  src=/api/fileinfo/ebook/GetEBookPageForOriImg?fileName=` +
                this.pagesData[
                  Math.floor(pageIndex - 3 < 0 ? pageIndex : pageIndex - 3)
                ].filePath +
                "/" +
                this.pagesData[
                  Math.floor(pageIndex - 3 < 0 ? pageIndex : pageIndex - 3)
                ].fileName +
                "&&pageIndex=" +
                (this.pagesData[
                  Math.floor(pageIndex - 3 < 0 ? pageIndex : pageIndex - 3)
                ].pageIndex -
                  1) +
                "&&outputWatermask=" +
                this.outputWatermark +
                " style='max-width: 100%;max-height: 100%;;object-fit:contain;margin: auto;pointer-events: none;user-select: none;'/></div></div>"
            );
          } else {
            var element = $(
              `<div><div data-index='${pageIndex}' style='margin: auto;border-right:2px solid #ccc ;background-size: 100% 100%;width: 100%;height: 100%;background-color: #fff;'><img  src=/api/fileinfo/ebook/GetEBookPageForOriImg?fileName=` +
                this.pagesData[Math.floor((pageIndex - 3) / 2)].filePath +
                "/" +
                this.pagesData[Math.floor((pageIndex - 3) / 2)].fileName +
                "&&pageIndex=" +
                (pageIndex - 3) +
                "&&outputWatermask=" +
                this.outputWatermark +
                " style='max-width: 100%;max-height: 100%;;object-fit:contain;margin: auto;pointer-events: none;user-select: none;'/></div></div>"
            );
          }
        }
        $("#magazine").turn("addPage", element, pageIndex);
      }
    },
    /* 获取电子书本数 */
    async getEBook(id, level) {
      this.loading = true;
      try {
        const res = await getEBook({
          id,
          level,
        });
        this.EBookData = res;
        this.beginCatalogueInfoId = this.EBookData[0].id;
        this.endCatalogueInfoId = this.findLastItemId(res[res.length - 1]);
        this.title = res[0].parentName;
        this.$refs.tree.setCurrentKey(this.EBookData[0].id); //默认选中第一条
        // this.handleNodeClick(this.EBookData[0]); //同时调用树点击事件
        this.getPeriodical({ curCatalogueInfoId: this.EBookData[0].id });
        // this.getBaseData(this.EBookData[0].id);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async getEBookForName(params) {
      this.loading = true;
      try {
        const res = await getTree(params);
        if (res.length === 0) return this.$message.info("暂无数据");
        this.EBookData = res;
        this.beginCatalogueInfoId = this.EBookData[0].id;
        this.endCatalogueInfoId = this.findLastItemId(res[res.length - 1]);
        this.title = res[0].parentName;
        this.$refs.tree.setCurrentKey(this.EBookData[0].id); //默认选中第一条
        // this.handleNodeClick(this.EBookData[0]); //同时调用树点击事件
        this.getPeriodical({ curCatalogueInfoId: this.EBookData[0].id });
        // this.getBaseData(this.EBookData[0].id);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    findLastItemId(obj) {
      // 检查对象是否有childList
      if (obj.childList && obj.childList.length > 0) {
        // 递归调用findLastItemId，传递childList中的最后一项
        return this.findLastItemId(obj.childList[obj.childList.length - 1]);
      } else {
        // 如果没有childList，返回当前项的id
        return obj.id;
      }
    },

    /* 上一张 */
    searchPre() {
      if (Math.abs(this.translateX) > 0) {
        this.translateX += 125;
      }
    },
    /* 下一张 */
    searchNext() {
      if (Math.abs(this.translateX) < (this.transData.length - 6) * 125) {
        this.translateX -= 125;
      }
    },
    // searchDClick(item, tindex) {
    //   $("#magazine").turn("page", this.searchData[tindex].pageIndex + 3);
    //   $(
    //     ".page-wrapper[page=" +
    //       (this.searchData[tindex].pageIndex + 3) +
    //       "]>.ebook-search-page",
    //     "#magazine"
    //   ).remove();
    //   var $page = $(
    //     ".page-wrapper[page=" + (this.searchData[tindex].pageIndex + 3) + "]",
    //     "#magazine"
    //   );
    //   if ($page.length == 1) {
    //     var html = '<div class="ebook-search-page">';
    //     this.searchData[tindex].words.forEach((sitem, index) => {
    //       let rect = {
    //         location: JSON.parse(JSON.stringify(sitem.location)),
    //       };
    //       if (this.searchData[tindex].pageIndex % 2 == 0) {
    //         rect.location.left -= this.searchData[tindex].width / 2;
    //       } else {
    //         rect.location.left -= 250;
    //       }
    //       html +=
    //         '<a class="ebook-search-mask" title="' +
    //         sitem.word +
    //         index +
    //         '"style="left:' +
    //         Math.floor(rect.location.left * this.scaleIndex) +
    //         "px;top:" +
    //         Math.floor(rect.location.top * this.scaleIndex) +
    //         "px;width:" +
    //         Math.floor(rect.location.width * this.scaleIndex) +
    //         "px;height:" +
    //         Math.floor(rect.location.height * this.scaleIndex) +
    //         'px">' +
    //         sitem.word +
    //         "</a>";
    //     });
    //     html += "</div>";
    //     $(html).appendTo($page);
    //   }
    // },
    async HangChang(index, item, callback) {
      let domWidth = document.body.clientWidth || document.body.scrollWidth;
      let domHeight = document.body.clientHeight || document.body.scrollHeight;

      this.$nextTick(() => {
        this.pageHeight = this.$refs.bodycontent.offsetHeight * 0.9;
        // this.pageWidth = 944;
        this.pageWidth = domWidth * 0.49;
      });
      this.transData = [];
      this.translateX = 0;
      // if ($("#magazine").turn("is")) {
      //   $("#magazine").turn("destroy");
      //   this.allPages = [];
      // }
      // this.EBookItem = item;
      await this.$nextTick(() => {
        // this.turnInit(item, callback);
        this.turnInit();
      });
    },
    /* 上一页 */
    prePage() {
      try {
        $("#magazine").turn("previous");
      } catch (error) {}
    },
    /* 下一页 */
    nextPage() {
      $("#magazine").turn("next");
    },
    /* 跳转到特定页面 */
    blurOut() {
      if (isNaN(this.paginationNum)) return;
      if (this.paginationNum * 1 > this.totalPage) {
        $("#magazine").turn("page", +this.totalPage);
      } else if (this.paginationNum * 1 < 1) {
        $("#magazine").turn("page", 1);
      } else {
        console.log("this.paginationNum", this.paginationNum);
        $("#magazine").turn("page", this.paginationNum * 1 + 2);
      }
      this.paginationNum = null;
    },
    gotoPage(page) {
      if ($("#magazine") && page > 0) {
        $("#magazine").turn("page", page);
      }
    },
    /* 跳转第一页 */
    firstPage() {
      $("#magazine").turn("page", 1);
    },
    /* 跳转最后一页 */
    lastPage() {
      $("#magazine").turn("page", this.allPages.length);
    },
    /* 放大缩小 */
    turnZoom() {
      this.cancelCon();
      if (this.scaleValue == 1) {
        this.isDisable = false;
        this.scaleValue++;
        $("#magazine").turn("zoom", 2);
        // $("#magazine").addClass("zoom-out");
      } else {
        this.isDisable = true;
        this.scaleValue--;
        $("#magazine").turn("zoom", 1);
        // $("#magazine").removeClass("zoom-out");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/**@media (max-width: 1600px) {
  .text_box {
    display: none;
  }
  .book_box {
    flex: 1;
    transition: 0.3s ease;
  }
}

@media (max-width: 1200px) {
  .left {
    display: none;
  }
  .right {
    flex: 1;
    transition: 0.3s ease;
    .zk-img {
      display: none;
      transition: 0.3s ease;
    }
  }
}

 */

// #magazine .turn-page {
//   pointer-events: none;
// }
/* WebKit 内核浏览器 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background: #9cc3ce;
  border-radius: 10px;
}

/* 其他浏览器 */
* {
  scrollbar-width: auto;
  scrollbar-color: #9cc3ce #f2f2f2;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background: #9cc3ce;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

.pages {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;

  .left {
    background: #812f12;
    height: 100%;
    overflow-y: auto;
    transition: 0.3s ease;
    // 隐藏滚动条
    scrollbar-width: none;
    -ms-overflow-style: none;

    .showname {
      font-size: 14px;
      overflow: hidden !important; // 溢出部分隐藏
      white-space: nowrap !important; //禁止自动换行
      text-overflow: ellipsis !important; // 使溢出部分以省略号显示
      display: block !important;
    }

    .title {
      background: #75290e;
      color: #fff;
      line-height: 48px;
      padding-left: 24px;

      h3 {
        overflow: hidden !important; // 溢出部分隐藏
        white-space: nowrap !important; //禁止自动换行
        text-overflow: ellipsis !important; // 使溢出部分以省略号显示
        display: block !important;
      }
    }
    ::v-deep .el-tree {
      background: #812f12;
      color: #fff;

      // 超出的文字显示省略号
      .el-tree-node__label {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-tree-node:focus > .el-tree-node__content {
        color: #edb122;
        background: #812f12;
      }
      .el-tree-node.is-current > .el-tree-node__content {
        color: #edb122;
        background: #812f12;
      }
      .el-tree-node__content:hover,
      .el-upload-list__item:hover {
        color: #edb122;
        background: #812f12;
      }
      .el-tree-node:focus > .el-tree-node__content {
        // color: #edb122;
        background: #812f12;
      }
      .el-icon-caret-right:before {
        color: #02b3be;
      }

      // 展开时三角图标颜色
      .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
        color: #02b3be;
      }
      // 未展开时三角图标颜色
      .el-icon-caret-right:before {
        color: #02b3be;
      }

      // 没有子节点时三角图标颜色（一般不显示）
      .el-tree-node__expand-icon.is-leaf::before {
        color: rgba(0, 0, 0, 0); // 透明
      }
    }
  }
  .right {
    display: flex;
    transition: 0.3s ease;
    background: #d3d3d3;
    width: 85%;
    .zk-img {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      transform: rotate(180deg);
      z-index: 999;
    }

    .book_box {
      width: 75%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .img_box {
        margin-bottom: 10px;

        span {
          display: inline-block;
          width: 114px;
        }
        .view {
          cursor: pointer;
          max-height: 100%;
          max-width: 100%;
          vertical-align: middle;
        }
        .search {
          margin-right: 200px;
          width: 300px;
          margin: 0 auto;

          margin: 0 150px;
          ::v-deep .el-input__suffix {
            display: flex;
            align-items: center;
          }
          ::v-deep .el-input__inner:focus {
            border: 1px solid transparent !important;
          }
        }
      }

      .book {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        // overflow: hidden;

        #magazine {
          width: 100%;
          height: 100%;

          .pages {
            width: 100%;
            height: 100%;
          }
        }
        // .zoom-out {
        //   transform: translate(-25%, -25%);
        // }

        .body-content {
          position: relative;
          box-sizing: border-box;
          height: 800px;
          margin: 0 auto;
          overflow: hidden;

          .dropwindow {
            border-radius: 10px;
            width: 150px;
            height: 92px;
            background: rgb(255, 255, 255);
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            display: none;
            box-shadow: 2px 2px 5px #666;
            border: 1px solid #aab4b4;
            transform: scale(1);
            padding: 15px 0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            -o-user-select: none;
            user-select: none;
          }

          .drop-list {
            line-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            flex: 1;
            color: black;
            cursor: pointer;
            transform: scale(1);
            font-size: 16px;
          }
          .drop-list:hover {
            background: #ccc;
          }

          .text1 {
            box-sizing: border-box;
            position: relative;
            box-sizing: border-box;
            margin: auto;
            background-size: 100% 100%;
            height: 100%;
            background-color: #fff;
            text-align: center;

            .pic {
              width: 100%;
              height: 100%;
              margin: auto;
            }
            .tempPic {
              width: 100%;
              height: 100%;
              margin: auto;
            }
          }
          .text1:nth-child(1) {
            border-right: 2px solid #ccc;
          }
        }
        .left_btn {
          cursor: pointer;
          background: #9cc3ce;
          width: 62px;
          height: 90px;
          opacity: 0.1;
          position: absolute;
          top: 692px;
          left: 15%;
          z-index: 999999;
          transform: translateX(-50%);
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 20px;
            color: #fff;
          }
        }
        .right_btn {
          cursor: pointer;
          background: #9cc3ce;
          width: 62px;
          height: 90px;
          opacity: 0.1;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 692px;
          right: 15%;
          z-index: 999999;
          transform: translateX(50%);
          height: 90px;
          i {
            font-size: 20px;
            color: #fff;
          }
        }
        .box {
          width: 745px;
          height: 90px;
          overflow: hidden;
          position: absolute;
          top: 692px;
          left: 50%;
          z-index: 99999;
          transform: translateX(-50%);
          .image_box {
            position: absolute;
            left: 0;
            white-space: nowrap;
            // position: absolute;
            // top: 735px;
            // left: 50%;
            // z-index: 99999;
            // transform: translateX(-50%);
            // background: pink;

            li {
              display: inline-block;
              width: 70px;
              height: 90px;
              opacity: 0.1;
              margin-right: 5px;

              img {
                object-fit: fill;
                width: 100%;
                height: 100%;
              }
            }
            li:last-child {
              margin: 0;
            }
            li:hover {
              opacity: 1;
            }
          }
        }

        .pagination {
          box-sizing: border-box;
          margin: 0 auto;
          margin-top: 20px;
          // width: 400px;
          z-index: 9;
          display: flex;
          align-items: center;
          height: 50px;
          //   background-color: #fff;
          .pagination-item {
            cursor: pointer;
            box-sizing: border-box;
            // width: 53px;
            width: 100px;
            height: 100%;
            line-height: 50px;
            font-size: 26px;
            text-align: center;
            margin-right: 3px;
            border-radius: 5px;
            background: #fff;
            color: #d3d3d3;
          }
          ::v-deep .pagination-input {
            width: 100px;
            height: 50px;
            margin-right: 3px;
            text-align: center;
            line-height: 50px;
            border-radius: 5px;
            background: #fff;
            color: #d3d3d3;

            .el-input,
            .el-input__inner {
              height: 50px;
            }

            .el-input__inner:focus {
              border: 1px solid transparent !important;
            }
            //   margin-top: 4px;
            //   box-sizing: border-box;
            //   padding: 0 20px;
            //   flex: 1;
            //   align-items: center;
          }
        }
        .pagination1 {
          box-sizing: border-box;
          margin: 0 auto;
          margin-top: 20px;
          width: 400px;
          z-index: 9;
          display: flex;
          align-items: center;
          height: 30px;
          //   background-color: #fff;
          .pagination-item {
            cursor: pointer;
            box-sizing: border-box;
            width: 53px;
            height: 100%;
            line-height: 30px;
            font-size: 26px;
            text-align: center;
            margin-right: 3px;
            border-radius: 5px;
            background: #fff;
            color: #d3d3d3;
          }
          ::v-deep .pagination-input {
            width: 100px;
            height: 30px;
            margin-right: 3px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            background: #fff;
            color: #d3d3d3;

            .el-input,
            .el-input__inner {
              height: 30px;
            }

            .el-input__inner:focus {
              border: 1px solid transparent !important;
            }
            //   margin-top: 4px;
            //   box-sizing: border-box;
            //   padding: 0 20px;
            //   flex: 1;
            //   align-items: center;
          }
        }
      }
    }

    .text_box {
      // width: 550px !important;
      width: 25%;
      height: 100%;
      background: #f5f5f5;
      padding: 9px 9px 0;
      overflow: auto;
      .text {
        position: relative;
        padding-top: 52px;
        // width: 590px;
        height: calc(100% - 60px);
        background: #fff;
        display: flex;
        justify-content: flex-end;

        ::v-deep .operation {
          padding-right: 26px;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          height: 50px;
          font-size: 18px;
          color: #000;
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #ccc;
          align-items: center;
          justify-content: flex-end;

          .el-radio__label {
            font-size: 18px;
            color: #000;
          }

          .el-radio__inner {
            width: 18px;
            height: 18px;
          }
        }
        .test1 {
          overflow-y: auto;
          font-size: 20px;
          color: #000;
          writing-mode: vertical-lr;
          writing-mode: tb-rl;
          font-family: 宋体;
          white-space: pre-wrap;
          line-height: 22px;
          letter-spacing: 2px;
        }
        .test1:focus {
          transition: border linear 0.1s, box-shadow linear 0.2s;
          box-shadow: 0 0 10px #66afe9; //向外漫射程度1
          -moz-box-shadow: 0 0 10px #66afe9; //向外漫射程度2
          -webkit-box-shadow: 0 0 10px #66afe9; //向外漫射程度3
          border: 1px solid #66afe9;
          outline: 0;
          padding: 9px;
        }

        .test2 {
          overflow-y: auto;
          font-size: 20px;
          color: #000;
          // writing-mode: vertical-lr;
          // writing-mode: tb-rl;
          font-family: 宋体;
          white-space: pre-wrap;
          line-height: 22px;
          letter-spacing: 2px;
        }
      }
      .btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 60px;
        border-top: 1px solid #cacaca;
        background: #fff;
        padding-right: 20px;

        .el-button {
          width: 128px;
          height: 40px;
          background: #4c69bb;
          font-size: 16px;
          color: #fff;
        }
        ::v-deep .el-button:focus {
          border: 1px solid transparent !important;
        }
      }
    }
  }
}
</style>

<style scoped>
.browser {
  display: flex;
  width: 100%;
  height: 100%;
}
.phone {
  display: flex;
  height: 100%;
}
.ebook-search-page {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999999;
}
</style>